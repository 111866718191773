<template>
  <div class="d-flex flex-column px-5 px-md-10 pt-8">
    <!-- Tabs -->
    <v-tabs v-model="tab" color="secundary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
    </v-tabs>
    <v-divider />
    <v-row class="tabs-toolbar align-self-sm-end justify-end mx-0 mb-12">
      <v-text-field
        v-model="searchText"
        :label="$t('common.search')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ id, content } in tabs" :key="id">
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-data-table
              v-if="content.length"
              :headers="headers" :items="content" :search="searchText" :sort-by="['createdAt']" :sort-desc="true" dense
              class="mt-6 table--has-pointer" @click:row="item => $router.push({ name: `${entity}-tickets-match-detail`, params:{ guildMatchId: item.id } })"
            >
              <template #[`item.date`]="{ item }">
                <span v-text="formatDate(item.date.start)" />
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'published'"
                  outlined label color="success" style="min-width: 5rem;"
                >
                  {{ $t('common.published') }}
                </v-chip>
                <v-chip
                  v-else
                  outlined label color="pending" style="min-width: 5rem;"
                >
                  {{ $t('common.pending') }}
                </v-chip>
              </template>
            </v-data-table>
            <empty-list v-else :content="$t('organization.sections.tickets')" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dateFilter from '@/utils/mixins/dateFilter'
import i18n from '@/plugins/i18n'

export default {
  name: 'GuildMatchList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
  },
  mixins: [dateFilter],
  props: {
    clusterId: { type: String, default: null },
  },
  data: () => ({
    tab: null,
    searchText: null,
  }),
  computed: {
    ...mapGetters({ rawGuildMatches: 'guildMatches/guildMatches' }),
    ...mapGetters('organization', ['clusters']),
    entity: ({ clusterId }) => clusterId ? 'cluster' : 'organization',
    tabs: ({ guildMatchesForPlaying, guildMatchesPlayed }) => [
      { id: 'forPlaying', name: i18n.t('cluster.tickets.filters.forPlaying'), content: guildMatchesForPlaying },
      { id: 'played', name: i18n.t('cluster.tickets.filters.played'), content: guildMatchesPlayed },
    ],
    headers: ({ clusterId }) => [
      { text: i18n.t('common.game'), value: 'name', cellClass: 'py-4' },
      ...(!clusterId ? [{ text: i18n.t('cluster.tickets.visitingCluster'), value: 'visitingCluster', cellClass: 'py-4' }] : []),
      { text: i18n.t('common.date'), value: 'date', cellClass: 'py-4' },
      { text: i18n.t('cluster.tickets.ticketCount'), value: 'ticketCount', cellClass: 'py-4' },
      { text: i18n.t('cluster.tickets.assignedTicketCount'), value: 'assignedTicketCount', cellClass: 'py-4' },
      { text: i18n.t('cluster.tickets.available'), value: 'available', cellClass: 'py-4' },
      ...(!clusterId ? [{ text: i18n.t('common.status'), value: 'status', cellClass: 'py-4' }] : []),
    ],
    prefilteredGuildMatches({ rawGuildMatches, clusterId }) {
      if (clusterId) return rawGuildMatches.filter(gM => gM.clusterId === clusterId && gM.ticketCount > 0)
      return rawGuildMatches
    },
    guildMatches: ({ prefilteredGuildMatches, clusters }) => prefilteredGuildMatches.map(gM => {
      const available = gM.ticketCount - gM.assignedTicketCount
      const visitingCluster = clusters.find(c => c.id === gM.clusterId)?.name
      const status = gM.ticketCount > 0 ? 'published' : null
      return { ...gM, visitingCluster, available, status }
    }),
    guildMatchesForPlaying: ({ guildMatches }) => guildMatches
      .filter(gM => new Date(gM.date.end.seconds * 1000) > new Date()),
    guildMatchesPlayed: ({ guildMatches }) => guildMatches
      .filter(gM => new Date(gM.date.end.seconds * 1000) < new Date()),
  },
}
</script>
